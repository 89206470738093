import PageLayoutPublic from "../PageLayoutPublic"
import Register from "../components/Register";
import "../css/Register.css";

function RegisterPage() {
  return <PageLayoutPublic children={<Register />} active="Register" />;
}


export default RegisterPage;
