import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import supabaseClient from '../utils/supabaseClient';

import '../css/Evaluation.css'
import evaluation from '../images/Apresentação_sem_título-1.png'

function Evaluation () {
	const location = useLocation();

	if (supabaseClient.auth.user() === null) {
		return <Navigate to="/login" state={{ from: location }} />;
	}

    return (
		<div className='evaluation-main-container'>
			<div className="evaluation-card">
                <img src={evaluation} />
			</div>
		</div>
    );
}

export default Evaluation;
