import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  ListGroup,
  Form
} from 'react-bootstrap';
import ProfileServices from "../core/ProfileServices";
import { Navigate, useLocation } from "react-router-dom";
import supabaseClient from "../utils/supabaseClient";
import axios from "axios";
//TODO USAR MESMA LOGICA DO REDIRECTING PARA VER SE O USER JA TEM EQUIPA

function Register() {
    let location = useLocation();

    const [teams, setTeams] = useState([]);

  const [formData, setFormData] = useState({
    projectName: '',
    projectTitle: '',
    projectDescription: '',
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [selectedTeam, setSelectedTeam] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Handle team creation logic here
  };

  const handleOkClick = async() => {
      try {
          // Perform the database update logic here
          console.log(`Selected team: ${selectedTeam.NAME}`);
          const email = supabaseClient.auth.user().email;
          const name = supabaseClient.auth.user().user_metadata.full_name;
          const user_id = supabaseClient.auth.user().id;
          const team_id = selectedTeam.IDTEAM;


          await axios.post("http://localhost:5000/joinTeam", {Name: name, Email: email, IDTEAM: team_id, user_id: user_id,  token: supabaseClient.auth.currentSession.access_token})
         
          window.location.href = "/home";
      } catch (error) {
          console.error("Error updating database:", error.message);
          alert("An error occurred while updating the database. Please try again.");
      }
  };

  const createTeam = async () => {
      try {
          const email = supabaseClient.auth.user().email;
          const name = supabaseClient.auth.user().user_metadata.full_name;
          const user_id = supabaseClient.auth.user().id;
          const {projectName, projectTitle, projectDescription} = formData;
          console.log("Creating team with: ", formData); // Check the payload structure here

          await axios.post("http://localhost:5000/createTeam", {Name: name, Email: email, user_id: user_id, ProjectName: projectName, ProjectTitle: projectTitle, ProjectDescription: projectDescription, token: supabaseClient.auth.currentSession.access_token})

         window.location.href = "/home";
      } catch (error) {
          console.error("Error updating database:", error.message);
          alert("An error occurred while updating the database. Please try again.");
      }


  };


  useEffect(() => {
    ProfileServices.getTeams(setTeams);
  }, []);

  if (supabaseClient.auth.user() === null) {
      return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <Container fluid className="team-page-container">
      <Row className="justify-content-center align-items-center h-100">
        {/* Create New Team Section */}
        <Col md={5}>
          <Card className="p-4 create-team-section">
            <Card.Body>
              <Card.Title>Create New Team</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formTeamName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="projectName"
                    value={formData.projectName}
                    onChange={handleInputChange}
                    placeholder="Enter team name"
                  />
                </Form.Group>
                <Form.Group controlId="formProjectTitle" className="mt-3">
                  <Form.Label>Project Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="projectTitle"
                    value={formData.projectTitle}
                    onChange={handleInputChange}
                    placeholder="Enter project titlççe"
                  />
                </Form.Group>
                <Form.Group controlId="formProjectDescription" className="mt-3">
                  <Form.Label>Project Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="projectDescription"
                    value={formData.projectDescription}
                    onChange={handleInputChange}
                    placeholder="Enter project description"
                  />
                </Form.Group>
                <Button onClick={createTeam} variant="primary" type="submit" className="mt-3">
                  Create Team
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        {/* Join a Team Section */}
        <Col md={5}>
          <Card className="p-4 join-team-section">
            <Card.Body>
              <Card.Title>Join a Team</Card.Title>
              <ListGroup className="scrollable-list">
                {teams.map((team) => (
                  <ListGroup.Item
                    key={team.IDTEAM}
                    className="team-item"
                    onClick={() => setSelectedTeam(team)}
                    active={selectedTeam?.IDTEAM === team.IDTEAM}
                  >
                    {team.NAME}

                    {/* Show the "OK" button only for the selected team */}
                    {selectedTeam?.IDTEAM === team.IDTEAM && (
                      <Button
                        variant="success"
                        className="ml-2"
                        onClick={handleOkClick}
                      >
                        OK
                      </Button>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

}

export default Register;