import React, { useState, useEffect, useRef } from "react";
import "../index.css";
import neecathon_logo from "../images/logo2023_semslogan_cropped.png";
import ProfileServices from "../core/ProfileServices";
import "../css/Navbar.css";
import supabaseClient from "../utils/supabaseClient";

export default function MyNav(props) {
	const [team, setTeam] = useState(undefined);
	const logout = useRef(null);

	function hover() {
		console.log("hover");
		logout.current.classList.remove("inv");
	}
	function unhover() {
		console.log("unhover");
		logout.current.classList.add("inv");
	}

	useEffect(() => {
		ProfileServices.getTeam(setTeam);
	}, []);

	if (team === undefined) {
		return (
			<div>
				<div className="navbar">
					<div className="navbar-options">
						<a href="/home">
							<img src={neecathon_logo} alt="logo" />
						</a>
					</div>
					<div className={(props.active === "Shop") ? "navbar-options active" : "navbar-options"}>
						<a href="/shop">
							Loja
						</a>
					</div>
					<div className = {(props.active === "Mentors") ? "navbar-options active" : "navbar-options"}>
						<a href="/mentors">
							Mentores
						</a>
					</div>
					<div className={props.active === "Profile" ? "team navbar-options active" : "team navbar-options"}>
						<a href="/profile">
							A Carregar...
						</a>
					</div>
				</div>
				<div ref={logout} className="navbar-logout inv">
					Logout
				</div>
			</div>
		);
	}

	return (
		<div>
			<div className="navbar">
				<div className="navbar-options">
					<a href="/home">
						<img src={neecathon_logo} alt="logo" />
					</a>
				</div>
				<div className={(props.active === "Shop") ? "navbar-options active" : "navbar-options"}>
					<a href="/shop">
						Loja
					</a>
				</div>
				<div className = {(props.active === "Mentors") ? "navbar-options active" : "navbar-options"}>
					<a href="/mentors">
						Mentores
					</a>
				</div>
				<div className = {(props.active === "Evaluation") ? "navbar-options active" : "navbar-options"}>
					<a href="/evaluation">
						Avaliação
					</a>
				</div>
				<div className = {(props.active === "BusinessModel") ? "navbar-options active" : "navbar-options"}>
					<a href="/business">
						Business Model
					</a>
				</div>
				<div className="team" onMouseOver={hover} onMouseLeave={unhover}>
					<div className={props.active === "Profile" ? "navbar-options active" : "navbar-options"}>
						<a href="/profile">
							{team[0].NAME}
						</a>
					</div>
					<div
						ref={logout}
						onMouseOver={hover}
						onMouseLeave={unhover}
						onClick={() => {
							supabaseClient.auth.signOut();
							window.location.href = "https://neecathon.neecist.org/login";
						}}
						className="navbar-logout inv"
					>
						Logout
					</div>
				</div>
			</div>
		</div>
	);
}
