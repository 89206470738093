import "../index.css";
import "../css/Footer.css";
import React from "react";

import fb_logo from "../images/fb.svg";
import insta_logo from "../images/insta.svg";
import twitter_logo from "../images/tt.svg";
import linkedin_logo from "../images/lin.svg";
import powered_by_neec from "../images/powered_by_neec.svg";

export default function LoginFooter() {
	return (
		<div className="login-footer">
			<div className="footer-partners">
				<a href="/parcerias">Parcerias</a>
			</div>
			<div className="footer-icons">
				<a href="https://www.facebook.com/NEECIST/" target="_blank" rel="noreferrer">
					<img src={fb_logo} alt="fb-logo" />
				</a>
				<a href="https://www.instagram.com/neecist/" target="_blank" rel="noreferrer">
					<img src={insta_logo} alt="insta-logo" />
				</a>
				<a href="https://www.twitter.com/neecist/" target="_blank" rel="noreferrer">
					<img src={twitter_logo} alt="twitter-logo" />
				</a>
				<a href="https://www.linkedin.com/company/neecist/mycompany/" target="_blank" rel="noreferrer">
					<img src={linkedin_logo} alt="linkedin-logo" />
				</a>
			</div>
			<div className="footer-neec">
				<a href="https://neecist.org" target="_blank" rel="noreferrer">
					<img src={powered_by_neec} alt="fb-logo" />
				</a>
			</div>
			<div className="login-footer-bar" />
		</div>
	);
}
