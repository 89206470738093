import React, { useState, useEffect } from 'react';
import supabaseClient from '../utils/supabaseClient';
import { Navigate, useLocation } from 'react-router-dom';
import ProfileServices from '../core/ProfileServices';

import "../css/Mentors.css"

const categories = [
	{
		name: "Hardware", 
		description: "Mentor com competências e conhecimento sobre todos os componentes de um sistema físico",
	},
	{
		name: "Programação", 
		description: "Mentor com competências nas principais linguagens de programação"
	},
	{
		name: "Modelação e impressão", 
		description: "Mentor com competências no desenvolvimento de modelos para impressão 3D"
	},
	{
		name: "Business", 
		description: "Mentor com competências sobre os conceitos fundamentais para o sucesso de um projeto a nível empresarial"
	},
	{
		name: "Marketing",
		description: "Mentor com competências sobre a melhor forma de desenhar o projeto, de forma a torná-lo atrativo"
	},
	{
		name: "Pitch",
		description: "Mentor com competências para aconselhar na concepção de uma apresentação breve do projeto"
	}
];

async function getMentors(setMentors) {
	try {
		let { data: Mentors, error } = await supabaseClient
			.from('Mentors')
			.select('*');
		if (error) throw error
		if (Mentors) {
			Mentors.sort((a, b) => a.name > b.name);
			console.log(Mentors);
			Mentors.forEach((mentor) => {
				let categories = [];
				if (mentor.hardware) categories.push('Hardware');
				if (mentor.software) categories.push('Software');
				if (mentor.modeling) categories.push('Modeling');
				if (mentor.business) categories.push('Business');
				if (mentor.marketing) categories.push('Marketing');
				if (mentor.pitch) categories.push('Pitch');
				mentor.categories = categories.join(', ');
			})
			setMentors(Mentors);
		}
	} catch (error) {
		alert(error.message);
	}
}

function sendNotification(mentor, team) {
	const discord_id = mentor.discord_id;
	const request = new XMLHttpRequest();

	request.open("POST", "https://discordapp.com/api/webhooks/1180304538226405376/lzUcKZlrOqIn6XlX803-jH2z2LUm_IESitOPPZpLCt_MqeQdm4n-w1STUubCSiV-x_-k");
	request.setRequestHeader('Content-type', 'application/json');
	const params = {
		username: "Notification Test",
		avatar_url: "",
		content: "A EQUIPA " + team[0].NAME + " PRECISA DE TI!" + " <@" + discord_id + ">",
	};
	console.log(team)
	console.log("Sending notification");
	request.send(JSON.stringify(params));
}

function Mentors() {
	const [team, setTeam] = useState(undefined);
	const [mentors, setMentors] = useState(undefined);
	const location = useLocation();

	useEffect(() => {
		ProfileServices.getTeam(setTeam);
		getMentors(setMentors);
	}, []);

	if (supabaseClient.auth.user() === null) {
		return <Navigate to="/login" state={{ from: location }} />;
	}

	if (mentors === undefined || team === undefined) return (
		<div className='mentors-main-container'>
			<div className="mentor-card">
				<h2>A Carregar...</h2>
			</div>
		</div>
	);
	
	else return (
		<div className='mentors-main-container'>
			<div className="mentor-card">
				<h2>Lista de Mentores</h2>
					{mentors.map((mentor) => {
						return (
							<div className="mentor-card-body" key={mentor.id}>
								<div className="mentor-card-info">
									{/* <img src={notify} alt="mentor-img" /> */}
									<p>{mentor.name}: {mentor.categories}</p>
								</div>
								<div className="mentor-card-icons">
									<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
										{
											mentor.available
												? <circle cx="20.4248" cy="20.4248" r="18.4606" fill="#2DE726" stroke="black" strokeWidth="3" />
											: <circle cx="20.4248" cy="20.4248" r="18.4606" fill="#DD0E0E" stroke="black" strokeWidth="3" />
											
										}
									</svg>
									<div onClick={() => sendNotification(mentor, team)} className="mentor-card-notify">
										<svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="34" cy="34" r="34" fill="#FFAF00" />
											<path d="M34.0001 58.1667C36.6705 58.1667 38.8334 56.0038 38.8334 53.3334H29.1667C29.1667 56.0038 31.3297 58.1667 34.0001 58.1667ZM48.5001 43.6667V31.5834C48.5001 24.1522 44.5488 17.9534 37.6251 16.3101V14.6667C37.6251 12.6609 36.0059 11.0417 34.0001 11.0417C31.9942 11.0417 30.3751 12.6609 30.3751 14.6667V16.3101C23.4513 17.9534 19.5001 24.1522 19.5001 31.5834V43.6667L14.6667 48.5001V50.9167H53.3334V48.5001L48.5001 43.6667Z" fill="black" />
										</svg>
									</div>
								</div>
							</div>
						)
					})}
			</div>
			<div className='mentor-descriptions'>
				{
					categories.map((category) => {
						return (
							<>
								<h2>{category.name}</h2>
								<p>{category.description}</p>
							</>
						)
					})
				}
			</div>
		</div>
	)
}

export default Mentors;
