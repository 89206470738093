import { Container, Button, Col, Row } from "react-bootstrap";
import { Auth } from "@supabase/ui";
import signIn from "../utils/signIn";
import supabaseClient from "../utils/supabaseClient";
import { FaGoogle } from "react-icons/fa";
import { Navigate } from "react-router-dom";

import horizontal_stripes from "../images/horizontal_stripes.svg";
import LoginFooter from "./LoginFooter.jsx";
import leftImage1 from "../images/planeta1.png";
import leftImage2 from "../images/planeta2.png";
import rightImage1 from "../images/planeta3.png";
import rightImage2 from "../images/planeta4.png";
import rightImage3 from "../images/foguete.png";

function Login() {
	if (supabaseClient.auth.user()) {
		
		return <Navigate to="/home" />;
	} else {
		return (
			<Container fluid className="p-0">
	<img className="horizontal_stripes" src={horizontal_stripes} alt="topimage" />
	<div className="container login-container">
		<h1 className="logo-login-page">NEECATHON</h1>
		<div className="edition">6ª Edição</div>
		<Auth.UserContextProvider supabaseClient={supabaseClient}>
			<Row className="align-items-center">
				{/* Left side with 2 images */}
				<Col xs={12} md={4} className="text-center mb-3 mb-md-0">
					<img src={leftImage1} alt="planet1" className="img-fluid mb-2 little-images image-shift-1" />
					<img src={leftImage2} alt="planet2" className="img-fluid little-images image-shift-2" />
				</Col>

				{/* Center with login button */}
				<Col xs={12} md={4} className="d-flex justify-content-center align-items-center login-button-col">
					<Button size="lg" onClick={() => signIn(supabaseClient)}>
						<FaGoogle /> Login com Google
					</Button>
				</Col>

				{/* Right side with 3 images */}
				<Col xs={12} md={4} className="text-center">
					<img src={rightImage1} alt="planet3" className="img-fluid mb-2 little-images image-shift-1" />
					<img src={rightImage2} alt="planet4" className="img-fluid mb-2 little-images image-shift-2" />
					<img src={rightImage3} alt="foguete" className="img-fluid little-images image-above" />
				</Col>
			</Row>
		</Auth.UserContextProvider>
	</div>
	
	<LoginFooter />
</Container>
		);
	}
}
export default Login;
