import React from "react";
import "../css/Styleh.css";
import stripes from "../images/vertical_stripes.svg"


export const Horario = () => {
	return (
		<div className="schedule">
			<div className="schedule-card">
				<div className="side-stripes">
					<img alt="stripes" src={stripes} />
				</div>
				<div className="schedule-card-content">
					<div className="schedule-card-title">
						<h1>Dia 1 - 02/12</h1>
					</div>
					<div className="schedule-card-body">
					</div>
				</div>
			</div>
			<div className="schedule-card">
				<div className="side-stripes">
					<img alt="stripes" src={stripes} />
				</div>
				<div className="schedule-card-content">
					<div className="schedule-card-title">
						<h1>Dia 1 - 02/12</h1>
					</div>
					<div className="schedule-card-body">
					</div>
				</div>
			</div>
		</div>
	);
};
