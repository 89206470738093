import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import supabaseClient from '../utils/supabaseClient';

import '../css/Evaluation.css';
import business from '../images/WhatsApp Image 2023-12-03 at 01.20.29.jpeg';

function BusinessModel () {
	const location = useLocation();

	if (supabaseClient.auth.user() === null) {
		return <Navigate to="/login" state={{ from: location }} />;
	}

    return (
		<div className='evaluation-main-container'>
			<div className="evaluation-card">
                <img src={business} />
			</div>
		</div>
    );
}

export default BusinessModel;
